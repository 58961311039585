<template>
  <div>
        <vue-header title="问诊" isReturn="true" />
        <div>
            <img src="../../../images/inquire.png" class="front hori-one"/>
            <img src="../../../images/hori2.png" class="front hori-two"/>
            <div class="step-mint">
              <div class="mint-color">您即将进入<span>[问诊]</span></div>
            </div>
            <div class="tips-alert">
              <div class="point-out">请如实填写问诊信息</div>
              <div class="point-out">避免检测结果出现误差</div>
            </div>
            <button class="click-photo" @click="questionFun">开始问诊</button>
        </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header' 
export default {
  name: 'confirmAsk',
  components:{
    vueHeader
  },
  methods:{
    //进入问诊
    questionFun(){
        let flow = sessionStorage.getItem('flow');
        let device = sessionStorage.getItem('device');
        let flow_step = "";
        if(flow){
          flow_step = JSON.parse(flow).flow_info.flow_step;
        }
        if((flow_step==5||flow_step==6)&&!(device=='android' || device=='windows')){
            this.$router.push({
                name:'prescribeQuestion'
            });
        }else{
            this.$router.push({
                name:'bianz'
            });
        }
    }
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'confirmAsk';
</style>